<template>
  <div class="register">
    <PageSubHeader :pageSubHeader="pageSubHeader" :key="componentKey" />

    <main>
      <section id="grocery_nonMember">

        <div class="form_box">
          <form
            action=""
            method="post"
            @submit.prevent
          >

            <div class="w1300 grocery_container" v-if="!isMember">

              <div class="main_title_box">
                <h3 class="main_title txt-bold">報名資料</h3>
                <div class="img_box">
                  <img src="@/statics/img/index/icon_title2.svg" alt="">
                </div>
              </div>

              <!-- 非會員報名 -->
              <div class="form_switch_box container">

                <!-- 已填過報名資料 -->
                <section class="form_area register_already">
                  <div class="form_input_box register_already_toggle register_already_output">

                    <div class="form_interactive">
                      <div class="half">
                        <div class="fixed_info w50">
                          <p class="fixed_info_title">姓名</p>
                          <p class="fixed_info_content">{{ applyName(user) }}</p>
                        </div>
                        <div class="fixed_info w50">
                          <p class="fixed_info_title">身份</p>
                          <p class="fixed_info_content">{{ applyLevel(user) }}</p>
                        </div>
                      </div>
                      <div class="half">
                        <div class="fixed_info w100">
                          <p class="fixed_info_title">身分證字號/居留證號碼</p>
                          <p class="fixed_info_content">{{ applyIdNum(user) }}</p>
                        </div>
                      </div>
                      <div class="fixed_info w50">
                        <p class="fixed_info_title">手機號碼</p>
                        <p class="fixed_info_content">{{ applyPhone(user) }}</p>
                      </div>
                      <div class="fixed_info w50">
                        <p class="fixed_info_title">院所名稱</p>
                        <p class="fixed_info_content">{{ applyHospitalName(user) }}</p>
                      </div>
                      <div class="fixed_info w100">
                        <p class="fixed_info_title">院所地址</p>
                        <p class="fixed_info_content">{{ applyHospitalAddr(user) }}</p>
                      </div>
                    </div>

                  </div>
                </section>

              </div>

            </div>

            <!-- 課程購買明細 -->
            <section id="form_detail" class="register_online_courses_grocery">
              <div class="w1300 grocery_container">

                <!-- 重複購買課程 -->
                <template v-if="boughtAlready.length > 0">
                  <div class="main_title_box">
                    <h3 class="main_title txt-bold">重複購買課程明細</h3>
                    <div class="img_box">
                      <img src="@/statics/img/index/icon_title2.svg" alt="">
                    </div>
                  </div>

                  <div class="form_input_table">

                    <!-- table area -->
                    <div class="table_box">
                      <table class="txt-center">
                        <thead>
                          <tr>
                            <th class="td-main txt-left">
                              <p>{{ tableHead1 }}</p>
                            </th>
                            <th class="min130">
                              <p>{{ tableHead2 }}</p>
                            </th>
                            <th class="min150">
                              <p>{{ tableHead3 }}</p>
                            </th>
                            <th class="min130">
                              <p>{{ tableHead4 }}</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody id="table_data">
                          <tr
                            v-for="tr in boughtAlready"
                            :key="tr.id"
                          >
                            <td :data-title="tableHead1" class="td-main txt-left">
                              <p >{{ tr.name }}</p>
                            </td>
                            <td :data-title="tableHead2"><p>{{ tr.video_count }}</p></td>
                            <td :data-title="tableHead3" class="td-input">
                              <p>{{ tr.title }}</p>
                            </td>
                            <td :data-title="tableHead4" class="td-price">
                              <p :data-price="tr.price[1].price">
                                <span>{{ tr.price[1].price }}</span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>

                <!-- 免費課程 -->
                <template v-if="boughtFree.length > 0">
                  <div class="main_title_box">
                    <h3 class="main_title txt-bold">免費課程明細</h3>
                    <div class="img_box">
                      <img src="@/statics/img/index/icon_title2.svg" alt="">
                    </div>
                  </div>

                  <div class="form_input_table">

                    <!-- table area -->
                    <div class="table_box">
                      <table class="txt-center">
                        <thead>
                          <tr>
                            <th class="td-main txt-left">
                              <p>{{ tableHead1 }}</p>
                            </th>
                            <th class="min130">
                              <p>{{ tableHead2 }}</p>
                            </th>
                            <th class="min150">
                              <p>{{ tableHead3 }}</p>
                            </th>
                            <th class="min130">
                              <p>{{ tableHead4 }}</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody id="table_data">
                          <tr
                            v-for="tr in boughtFree"
                            :key="tr.id"
                          >
                            <td :data-title="tableHead1" class="td-main txt-left">
                              <p >{{ tr.course.name }}</p>
                            </td>
                            <td :data-title="tableHead2"><p>{{ tr.course.video_count }}</p></td>
                            <td :data-title="tableHead3" class="td-input">
                              <p>{{ tr.title }}</p>
                            </td>
                            <td :data-title="tableHead4" class="td-price">
                              <p :data-price="tr.total">
                                <span>{{ tr.total }}</span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>

                <!-- 本次可購買課程 -->
                <template v-if="boughtAvailable.length > 0">
                  <div class="main_title_box">
                    <h3 class="main_title txt-bold">本次可購買課程明細</h3>
                    <div class="img_box">
                      <img src="@/statics/img/index/icon_title2.svg" alt="">
                    </div>
                  </div>

                  <div class="form_input_table">

                    <!-- table area -->
                    <div class="table_box">
                      <table class="txt-center">
                        <thead>
                          <tr>
                            <th class="td-main txt-left">
                              <p>{{ tableHead1 }}</p>
                            </th>
                            <th class="min130">
                              <p>{{ tableHead2 }}</p>
                            </th>
                            <th class="min150">
                              <p>{{ tableHead3 }}</p>
                            </th>
                            <th class="min130">
                              <p>{{ tableHead4 }}</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody id="table_data">
                          <tr
                            v-for="tr in boughtAvailable"
                            :key="tr.id"
                          >
                            <td :data-title="tableHead1" class="td-main txt-left">
                              <p >{{ tr.course.name }}</p>
                            </td>
                            <td :data-title="tableHead2"><p>{{ tr.course.video_count }}</p></td>
                            <td :data-title="tableHead3" class="td-input">
                              <p>{{ tr.title }}</p>
                            </td>
                            <td :data-title="tableHead4" class="td-price">
                              <p :data-price="tr.total">
                                <span>{{ tr.total }}</span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="form_interactive">
                      <div class="fixed_info w50">
                        <p class="fixed_info_title">付款方式</p>
                        <p class="fixed_info_content">{{ payType }}</p>
                      </div>
                      <div class="fixed_info w50">
                        <p class="fixed_info_title">總金額</p>
                        <p class="fixed_info_content">{{ priceTotal }}</p>
                      </div>
                    </div>
                  </div>
                </template>

              </div>
            </section>

          </form>

          <!-- 綠界金流按鈕 -->
          <div v-html="resHtml" v-if="boughtAvailable.length > 0"></div>
          <div class="errMsg txt-center txt-red">
            <p class="h3">{{ paymentErrorMsg }}</p>
          </div>
          <div class="mycourse_btn txt-center" v-if="showBtn">
            <router-link
              to="/register/online/mycourse"
              class="btn"
            >我的課程</router-link>
          </div>
        </div>

      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader } from '@/lib/const';
import { courseApply, userInfo } from '@/lib/http';
import { fractionFilter } from '@/lib/public';
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'RegisterOnlineConfirm',
  data() {
    return {
      /** Static Data */
      ...RegisterOnlineSubHeader,
      componentKey: 0,
      showBtn: false,
      tableHead1: '課程名稱',
      tableHead2: '集數',
      tableHead3: '收據抬頭',
      tableHead4: '金額',
      isMember: false,
      boughtFree: [],
      boughtAlready: [],
      boughtAvailable: [],

      /** Axios Data */
      resHtml: '',
      user: {},
      order: {},
    };
  },
  created() {
    // this.boughtFree = JSON.parse(this.$cookies.get('boughtFree'));
    // this.boughtAlready = JSON.parse(this.$cookies.get('boughtAlready'));
    // this.boughtAvailable = JSON.parse(this.$cookies.get('boughtAvailable'));

    // const fractionsCollection = JSON.parse(this.$cookies.get('fractionsCollection'));
    const course = JSON.parse(this.$cookies.get('boughtTarget'));
    const payId = JSON.parse(this.$cookies.get('payId'));

    /** 付款資訊送出 */
    courseApply({ course, pay_id: payId }).then((result) => {
      if (result.result) {
        this.order = result.result.data;
        this.boughtFree = result.result.freeCourse;
        this.boughtAvailable = result.result.detail;
        this.resHtml = result.result.html;
      }
      this.forceRerender();
    });

    /** 取得使用者資訊 */
    if (this.hasToken) {
      userInfo().then((result) => {
        this.user = result;
      });
    }
  },
  computed: {
    ...mapState([
      'level',
    ]),
    ...mapGetters([
      'hasToken',
    ]),
    // computedPaymentTarget() {
    //   const tableDataCopy = JSON.parse(this.$cookies.get('paymentTarget'));
    //   const fractionsCopy = JSON.parse(this.$cookies.get('fractionsCollection'));
    //   const result = [];
    //   tableDataCopy.forEach((data) => {
    //     result.push({
    //       uuid: data.uuid,
    //       title: data.title,
    //       fraction: this.fractionFilter(fractionsCopy, data.uuid),
    //     });
    //   });
    //   return result;
    // },
    payType() {
      const payID = _.get(this.order, 'pay_id', null);
      if (parseInt(payID, 10) === 1) {
        return '信用卡';
      }
      return '';
    },
    priceTotal() {
      // let amount = 0;
      // this.boughtAvailable.forEach((data) => {
      //   amount += data.price[this.level].price * 1;
      // });
      // return amount;
      return _.get(this.order, 'total');
    },
    paymentErrorMsg() {
      const freeNum = this.boughtFree.length;
      const availableNum = this.boughtAvailable.length;

      if (freeNum === 0 && availableNum === 0) {
        return '無可操作課程，請重新選購';
      }
      if (freeNum > 0 && availableNum === 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showBtn = true;
        return '免費課程已加入清單，請至「我的課程」列表確認';
      }
      return '';
    },
  },
  methods: {
    /** 姓名 */
    applyName(user) {
      return _.get(user, 'realname', '');
    },
    /** 身份 */
    applyLevel(user) {
      const userLevel = _.get(user, 'level', '');
      if (userLevel && parseInt(userLevel, 10) === 4) {
        return '護理人員';
      }
      return '醫師';
    },
    /** 身分證字號 */
    applyIdNum(user) {
      return _.get(user, 'id_number', '');
    },
    /** 手機 */
    applyPhone(user) {
      return _.get(user, 'phone', '');
    },
    /** 院所名稱 */
    applyHospitalName(user) {
      return _.get(user, 'jobs.hospital_name', '');
    },
    /** 院所地址 */
    applyHospitalAddr(user) {
      const city = _.get(user, 'jobs.hospital_city', '');
      const area = _.get(user, 'jobs.hospital_area', '');
      const zip = _.get(user, 'jobs.hospital_zipcode', '');
      const addr = _.get(user, 'jobs.hospital_addr', '');
      return `${city} ${area} ${zip} ${addr}`;
    },
    /**
     * Force PageSubheader To Update Its Count
     */
    forceRerender() {
      this.componentKey += 1;
    },
    fractionFilter,
  },
  components: {
    PageSubHeader,
  },
};
</script>
